import Vue from 'vue' 
import Router from 'vue-router'

Vue.use(Router)

const routers = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */'@/views/index.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */'@/views/About/index.vue'),
    meta: { title: '关于千门' }
  },
  {
    path: '/cost',
    name: 'cost',
    component: () => import(/* webpackChunkName: "about" */'@/views/Cost/index.vue'),
    meta: { title: '价值千门' }
  },
  {
    path: '/wisdom',
    name: 'wisdom',
    component: () => import(/* webpackChunkName: "about" */'@/views/Wisdom/index.vue'),
    meta: { title: '智慧千门' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */'@/views/contact.vue'),
    meta: { title: '联系我们' }
  },
]

export default new Router({
  mode: 'history',
  routes: routers
})