<template>
  <div id="app">
    <Layout />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Layout from './layout/index.vue'
import Footer from './layout/footer.vue'
export default {
  name: 'App',
  components: {
    Layout,
    Footer
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-size: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
