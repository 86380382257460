<template>
  <div class="header">
    <img class="icon" src="@/assets/logo.png" alt="">
    <div class="menu">
      <div v-bind:class="['menu__item', $route.path == item.url ? 'menu__active' : '']  " v-for="item in menu" :key="item.path">
        <span @click="changeMenu(item.path)">{{ item.name }}</span>
        <div v-if="item.isChildren" class="subMenu">
          <div v-for="res in item.subMenu" @click="handleSubMenu(item.url, res.index)" :key="res.index" class="sub_menuTitle">{{res.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      menu: [
        {
          name: '首页',
          url: '/index',
          path: '/index',
          isChildren: false
        },
        {
          name: '关于千门',
          url: '/about',
          path: '/about?data=1',
          isChildren: true,
          subMenu: [
            { title: '公司简介', index: 1 },
            { title: '投资理念', index: 2 },
            { title: '企业文化', index: 3 },
            { title: '专业团队', index: 4 }
          ]
        },
        {
          name: '价值千门',
          url: '/cost',
          path: '/cost?data=1',
          isChildren: true,
          subMenu: [
            { title: '申赎流程', index: 1 },
            { title: '风控体系', index: 2 },
            { title: '旗下基金', index: 3 }
          ]
        },
        {
          name: '智慧千门',
          url: '/wisdom',
          path: '/wisdom?data=1',
          isChildren: true,
          subMenu: [
            { title: '投资者教育', index: 1 },
            { title: '千门公告', index: 2 }
          ]
        },
        {
          name: '联系我们',
          url: '/contact',
          path: '/contact?data=1'
        }
      ]
    }
  },
  methods: {
    changeMenu(path) {
      this.$router.push({ path: path })
    },
    handleSubMenu(url, index) {
      this.$router.push({ path: url, query: { data: index } })
    }
  }
}
</script>

<style>
.header {
  width: 1200px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.icon {
  height: 50px;
}
.menu {
  width: 600px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.menu__item {
  height: 80px;
  width: 120px;
  font-size: 16px;
  color: #333333;
  line-height: 80px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}
.menu__item:after {
  position: absolute;
  top: 76px;
  left: 16px;
  content: "";
  height: 4px;
  width: 40px;
  background-color: transparent;
}
.menu__active {
  color: #AD3026;
  font-weight: 600;
}
.menu__active:after {
  position: absolute;
  top: 76px;
  left: 40px;
  content: "";
  height: 4px;
  width: 40px;
  background-color: #AD3026;
}
.menu__item:hover .subMenu {
  display: block;
}
.subMenu {
  width: 120px;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  top: 90px;
  display: none;
}
.subMenu:before {
  content: "";
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  position: absolute;
  left: 50px;
  top: -10px
}
.sub_menuTitle {
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  padding: 10px 0;
  margin: 0 15px;
  border-bottom: 1px solid #eee;
  font-weight: 400;
}
.sub_menuTitle:last-child {
  border-bottom: none;
}
</style>